import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
import { STOCK_COINS_STATUS } from '../../../mixins/enum'
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Paginate
  },
  data() {
    return {
      busy: false,
      textInput: '',
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      stock_coins_status: STOCK_COINS_STATUS,
      fields: [
        { 
          key: 'code', 
          label: 'รหัส',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-xs-1/2 py-0.5',
          sortable: true 
        },
        { 
          key: 'name', 
          label: 'รายการ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 py-0.5',
          sortable: true , 
        },
        { 
          key: 'price', 
          label: 'ราคา', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 text-right py-0.5',
          sortable: true
        },
        { 
          key: 'leave_head', 
          label: 'เว้นหัว', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 text-right py-0.5',
          sortable: true
        },
        { 
          key: 'except', 
          label: 'เว้นท้าย', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 text-right py-0.5',
          sortable: true
        },
        { 
          key: 'balance', 
          label: 'คงเหลือ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 text-right py-0.5',
          sortable: true
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'text-right font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 py-0.5',
          class: 'text-right' 
        }
      ],
      items: [
        {
          uuid: '123456a',
          code: '1909-0001',
          name: '0.35 น้ำตาล',
          date: '24/09/2019',
          brand: 'Winner',
          price: 115.00,
          unit: 'เมตร',
          cost: 65.62,
          balance: -1.38,
          balance_status: 0,
          status: 'เปิดใช้งาน',
          except:0,
          leave_head: 0
        },
        {
          uuid: '123456b',
          code: '1910-0002',
          name: '0.35 น้ำเงิน',
          date: '24/09/2019',
          brand: 'BlueScope',
          price: 105.00,
          unit: 'เมตร',
          cost: 59.23,
          balance: 8.34,
          balance_status: 1,
          status: 'เปิดใช้งาน',
          except:0,
          leave_head: 0
        },
        {
          uuid: '123456b',
          code: '1910-0002',
          name: '0.35 น้ำเงิน',
          date: '24/09/2019',
          brand: 'BlueScope',
          price: 105.00,
          unit: 'เมตร',
          cost: 59.23,
          balance: 8.34,
          balance_status: 2,
          status: 'รับเข้าใหม่',
          except:0,
          leave_head: 0
        }
      ]
     }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onClose () {
      this.$emit('onGoodsPeListCloseHandler', {
        status: false
      })
    },
    onSelected(item) {
      this.$emit('onGoodsPeListCloseHandler', {
        status: true,
        data: item
      })
    },
    async onSearchHandler (currentPage) {
      this.busy = true
      setTimeout(() => {
        this.busy = false
      }, 500)
    }
  }
}
