import Deposits from '@/components/widgets/deposits'
import DatePicker from 'vue2-datepicker'

export default {
  components: {
    DatePicker,
    Deposits
  },
  props: {
    data: {
      type: Object,
      default: {}
    },
    items: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      depositEnabled: false,
      formData: {
        bill_note: '',
        remaining_length: 0,
        length_being_cut: 0,
        after_being_cut: 0,
        bill_summary: 0,
        bill_cod: 0,
        bill_discount_percentage: 0,
        bill_discount_amt: 0,
        bill_after_discount: 0,
        bill_deposit_number: '',
        bill_deposit_amt: 0,
        bill_after_deposit: 0,
        bill_nettotal_amt: 0,
        bill_tax_use: true,
        // bill_tax_type: 0,
        bill_percent_tax: 0,
        bill_value_added_tax: 0,
        bill_grand_total: 0,
        bill_products: []
      }
    }
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.formData = newValue
        }        
      },
      deep: true
    },
    items: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.formData.bill_products = newValue
          this.getGrandTotal()
        }
      },
      deep: true
    },
    formData: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.$emit('summaryData', { 
            summary: newValue
          })
        }
      },
      deep: true
    }
  },
  methods: {
    /** เปิด dialog ค้นหาข้อมูลใบมัดจำ */
    onOpenDepositHandler() {
      this.depositEnabled = !this.depositEnabled
    },
    /** เพิ่มข้อมูลใบมัดจำ */
    onInitDepositSettings(e) {
      this.depositEnabled = false
      if (e.status) {
        this.formData.bill_deposit_amt = 0
        this.formData.bill_after_deposit = 0
        this.formData.bill_cod = 0
        this.formData.bill_nettotal_amt = 0

        const data = e.data
        this.formData.bill_deposit_number = data.doc_no
        this.formData.bill_deposit_amt = data.deposit
        this.formData.bill_after_deposit = this.formData.bill_after_discount - data.deposit
        
        this.getGrandTotal()
      }
    },
    resetDeposit() {
      this.formData.bill_deposit_number = ''
      this.formData.bill_deposit_amt = 0
      this.formData.bill_after_deposit = 0

      this.getGrandTotal()
    },
    onTaxTypeChange(e) {
      this.getGrandTotal()
    },
    onTaxEnable(e) {
      this.getGrandTotal()
    },
    /** ข้อมูลยอดรวมสุทธิ */
    getGrandTotal() {
      const products = this.formData.bill_products
      if (products) {
        this.formData.bill_summary = 0

        for (let index = 0; index < products.length; index++) {
          const element = products[index]
          this.formData.bill_summary += parseFloat(element.total_length) * parseFloat(element.price_per_unit)
        }
        
        if (this.formData.bill_discount_percentage > 0) {
          let percent = ((this.formData.bill_summary / 100) * this.formData.bill_discount_percentage).toFixed(2)
          this.formData.bill_discount_amt = Number(percent)
          // .toLocaleString(
          //   "th-TH",
          //   {
          //     minimumFractionDigits: 2
          //   }
          // )
          this.formData.bill_after_discount = this.formData.bill_summary - percent
        } else {
          this.formData.bill_discount_amt = 0
          this.formData.bill_after_discount = this.formData.bill_summary
        }

        this.formData.bill_cod = this.formData.bill_after_discount
        if (this.formData.bill_deposit_amt > 0) {
          this.formData.bill_after_deposit = this.formData.bill_after_discount - this.formData.bill_deposit_amt
          this.formData.bill_cod = this.formData.bill_after_deposit
        } else {
          this.formData.bill_after_deposit = this.formData.bill_after_discount
          this.formData.bill_cod = this.formData.bill_after_discount
        }

        this.formData.bill_nettotal_amt = this.formData.bill_cod
        this.formData.bill_value_added_tax = 0
        if (this.formData.bill_tax_use) {
          this.formData.bill_value_added_tax = Number(((parseFloat(this.formData.bill_nettotal_amt) * 7) / 107).toFixed(2))
        }
        // .toLocaleString(
        //   "th-TH",
        //   {
        //     minimumFractionDigits: 2
        //   }
        // )
        // if (this.formData.bill_tax_type === '0') {
        //   this.formData.bill_grand_total = Number((parseFloat(this.formData.bill_nettotal_amt) + parseFloat(this.formData.bill_value_added_tax)).toFixed(2))
        // } else {
        //   this.formData.bill_grand_total = Number(parseFloat(this.formData.bill_nettotal_amt).toFixed(2))
        // }
        // .toLocaleString(
        //   "th-TH",
        //   {
        //     minimumFractionDigits: 2
        //   }
        // )
      }
    },
    onclear() {
      this.formData.bill_note = ''
      this.formData.bill_summary = 0
      this.formData.bill_cod = 0
      this.formData.bill_discount_percentage = 0
      this.formData.bill_discount_amt = 0
      this.formData.bill_after_discount = 0
      this.formData.bill_deposit_number = ''
      this.formData.bill_deposit_amt = 0
      this.formData.bill_after_deposit = 0
      this.formData.bill_nettotal_amt = 0
      this.formData.bill_before_vat = 0
      this.formData.bill_tax_use = true
      this.formData.bill_tax_type = 0
      this.formData.bill_percent_tax = 0
      this.formData.bill_value_added_tax = 0
      this.formData.bill_grand_total = 0
      this.formData.bill_products = []

      this.formData.remaining_length = 0
      this.formData.length_being_cut = 0
      this.formData.after_being_cut = 0
    }
  }
}